import React, {useEffect, useRef, useState} from 'react';
import {Box, Stack, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import RequiredCheckbox from './Shared/RequiredCheckbox';
import RichEditor from './Shared/RichEditor';
import RichEditorResultText from './Shared/RichEditorResultText';
import {stripHtmlTags} from './AdditionalNotes';

const SingleLine = ({
                        index,
                        id,
                        value,
                        onChange,
                        description,
                        enableValidation,
                        isEditMode,
                        isFormArchived,
                        readonly,
                        placeholderValues,
                        accessLevel,
                        onUpdateDescription,
                        componentToEditIndex,
                        submitMode,
                    }) => {
    const [currentValue, setCurrentValue] = useState(description);
    const prevPropRef = useRef();

    useEffect(() => {
        setCurrentValue(description)
    }, [description]);

    useEffect(() => {
        if (prevPropRef.current === index && componentToEditIndex !== prevPropRef.current) {
            onUpdateDescription({index, description: stripHtmlTags(currentValue), formattedDescription: currentValue});
        }
        prevPropRef.current = componentToEditIndex;
    }, [componentToEditIndex]);

    return (
        <Box>
            {isEditMode
                ? <>
                    <RichEditor value={description} onChange={onChange} id={id} index={index} isEditMode={isEditMode}
                                isFormLive={isFormArchived} accessLevel={accessLevel} currentDescription={currentValue}
                                setCurrentDescription={setCurrentValue}/>

                    <Stack flexDirection="row"
                           justifyContent="space-between"
                           gap={3}>
                        <RequiredCheckbox enableValidation={enableValidation} index={index} onChange={onChange}
                                          isFormArchived={isFormArchived}/>
                    </Stack>
                </>
                : <>
                    <RichEditorResultText description={description} placeholderValues={placeholderValues}
                                          readonly={readonly} enableValidation={enableValidation}/>
                    {!readonly
                        ? <TextField fullWidth value={value} required={enableValidation} disabled={!submitMode}
                                     onChange={e => e.target.value.length < 100
                                         ? onChange({id, value: e.target.value})
                                         : {}}
                        />
                        : <Typography sx={{
                            width: {xs: '80vw', sm: '60vw', md: '45vw', lg: '45vw', xl: '45vw'},
                            wordWrap: 'break-word',
                            overflowWrap: 'break-word'
                        }}>
                            {value}
                        </Typography>
                    }
                </>
            }
        </Box>
    );
};

SingleLine.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    index: PropTypes.number,
    validation: PropTypes.object,
    description: PropTypes.string,
    onChange: PropTypes.func,
    enableValidation: PropTypes.bool,
    isEditMode: PropTypes.bool,
    isFormArchived: PropTypes.bool,
    readonly: PropTypes.bool,
    placeholderValues: PropTypes.object,
    accessLevel: PropTypes.number,
    onUpdateDescription: PropTypes.func,
    componentToEditIndex: PropTypes.number,
    submitMode: PropTypes.bool,
};

export default SingleLine;