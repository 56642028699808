import React, {useMemo, useState} from 'react';
import Typography from '@mui/material/Typography';
import {Alert, Box, InputAdornment} from '@mui/material';
import PropTypes from 'prop-types';
import {validateFieldLength} from '../../../utility/validationUtil';
import AdmicityForm from '../../../shared-components/AdmicityForm';
import LogoUploader from './LogoUploader';
import useUser from '../../../utility/hooks/useUser';
import {ROLES} from '../../../constants/roles';

const validateDFENumber = (value) => {
    const isValid = /^\d{3}\d{4}$/.test(value);
    return {
        isValid,
        message: isValid ? '' : 'DFE number must be in the format: 3 digits for authority and 4 digits for school number',
    };
};

const validateInterval = (value) => {
    const numericValue = +value;
    const isValid = Number.isInteger(numericValue) && numericValue >= 0 && numericValue <= 1000;

    return {
        isValid,
        message: isValid ? '' : 'The value must be a whole number between 0 and 1000. Enter 0 to disable.',
    };
};

const validateWebDomain = (value) => {
    const isValid = /^([a-zA-Z0-9][a-zA-Z0-9-_]*\.)*[a-zA-Z0-9]*[a-zA-Z0-9-_]*[[a-zA-Z0-9]+$/.test(value);
    return {
        isValid,
        message: isValid ? '' : 'Enter a domain (e.g., hogwarts.admicity.co.uk)',
    };
};

const SchoolSetupForm = ({editMode, schoolInfo, onSubmit}) => {
    const [showAlert, setShowAlert] = useState(false);
    const {user} = useUser();

    const handleSubmit = (payload) => {
        const body =
            !editMode
                ? {
                    name: payload.name,
                    dfeNumber: payload.dfeNumber,
                    subDomain: payload.domain,
                    domain: process.env.REACT_APP_DOMAIN,
                    notes: payload.notes,
                    registrationInviteConfigOption: payload.registrationInviteConfigOption,
                    emailAddresses: payload.emailAddresses,
                    noAccountAutoReminderInterval: payload.noAccountAutoReminderInterval,
                    needConfirmAutoReminderInterval: payload.needConfirmAutoReminderInterval,
                    syncInterval: payload.syncInterval,
                    hideBoarderGroups: payload.hideBoarderGroups,
                    hideHouseGroups: payload.hideHouseGroups,
                    logo: payload.logo
                }
                : {
                    id: schoolInfo.id,
                    name: payload.name,
                    dfeNumber: payload.dfeNumber,
                    notes: payload.notes,
                    registrationInviteConfigOption: payload.registrationInviteConfigOption,
                    emailAddresses: payload.emailAddresses,
                    noAccountAutoReminderInterval: payload.noAccountAutoReminderInterval,
                    needConfirmAutoReminderInterval: payload.needConfirmAutoReminderInterval,
                    syncInterval: payload.syncInterval,
                    hideBoarderGroups: payload.hideBoarderGroups,
                    hideHouseGroups: payload.hideHouseGroups,
                    logo: payload.logo
                };

        setShowAlert(schoolInfo.hideBoarderGroups !== payload.hideBoarderGroups ||
            schoolInfo.hideHouseGroups !== payload.hideHouseGroups);

        onSubmit(body);
    };

    const textFields = useMemo(() => (
        [
            {
                label: 'School Name',
                name: 'name',
                initialValue: schoolInfo.name,
                validate: (value) => validateFieldLength(value, 400)
            },
            {
                label: 'DFE Number',
                name: 'dfeNumber',
                initialValue: schoolInfo.dfeNumber,
                validate: validateDFENumber
            },
            {
                label: 'School Web Domain at Admicity',
                name: 'domain',
                initialValue: schoolInfo.domain,
                helperText: 'Enter a domain (e.g., hogwarts.admicity.co.uk)',
                disabled: editMode,
                InputProps: (!editMode
                    ? {
                        endAdornment: (
                            <InputAdornment position="end">
                                <Typography>{process.env.REACT_APP_DOMAIN}</Typography>
                            </InputAdornment>
                        ),
                    }
                    : {}),
                validate: validateWebDomain
            },
            {
                label: 'Email addresses to send information about profiles changes',
                name: 'emailAddresses',
                required: false,
                initialValue: schoolInfo.emailAddresses,
            },
            {
                label: 'Auto Reminder Interval in hours for unregistered contacts (0 - disabled)',
                name: 'noAccountAutoReminderInterval',
                required: true,
                initialValue: schoolInfo.noAccountAutoReminderInterval,
                validate: validateInterval
            },
            {
                label: 'Auto Reminder Interval in hours for unconfirmed profiles (0 - disabled)',
                name: 'needConfirmAutoReminderInterval',
                required: true,
                initialValue: schoolInfo.needConfirmAutoReminderInterval,
                validate: validateInterval
            },
            {
                label: 'Synchronization Interval in hours (0 - disabled)',
                name: 'syncInterval',
                required: true,
                initialValue: schoolInfo.syncInterval,
                validate: validateInterval
            },
            {
                label: 'Notes',
                name: 'notes',
                initialValue: schoolInfo.notes,
                multiline: true,
                rows: 4,
                required: false
            }
        ]
    ), [schoolInfo]);

    const options = useMemo(() => [
        {
            label: 'Hide Boarder Groups',
            name: 'hideBoarderGroups',
            type: 'switch',
            initialValue: schoolInfo.hideBoarderGroups
        },
        {
            label: 'Hide House Groups',
            name: 'hideHouseGroups',
            type: 'switch',
            initialValue: schoolInfo.hideHouseGroups
        }
    ], [schoolInfo]);

    const customFields = useMemo(() =>
            [
                {
                    name: 'logo',
                    initialValue: schoolInfo.logo,
                    render: props => <LogoUploader key="logo" {...props}/>
                }
            ]
        , [schoolInfo.logo]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            sx={{
                width: {
                    xs: '100%',
                    sm: '75%',
                    md: '50%',
                    lg: '50%',
                },
            }}
        >
            {
                showAlert
                    ? <Alert
                        sx={{marginTop: 2}}
                        severity="info"
                        onClose={() => setShowAlert(false)}
                    >
                        Changes to the &quot;Hide House Groups&quot; or &quot;Hide Border Groups&quot; groups will take
                        effect during the next
                        synchronization, scheduled to occur in 1 hour.
                    </Alert>
                    : ''
            }
            <AdmicityForm
                textFields={textFields}
                options={options}
                custom={user.role === ROLES.SCHOOL_ADMIN ? customFields : []}
                handleSubmit={handleSubmit}
                buttonText="Save"
                actionsContainerProps={{
                    container: {
                        justifyContent: 'flex-end'
                    },
                    button: {
                        fullWidth: false
                    }
                }}
            />
        </Box>
    );
};

SchoolSetupForm.defaultProps = {
    editMode: false
};

SchoolSetupForm.propTypes = {
    editMode: PropTypes.bool,
    schoolInfo: PropTypes.object,
    onSubmit: PropTypes.func
};

export default SchoolSetupForm;