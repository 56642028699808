import React from 'react';
import {Checkbox, Divider, ListItemText, MenuItem, Stack, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import useTextField from '../../../utility/hooks/useTextField';
import {validateFieldLength} from '../../../utility/validationUtil';
import FormBuilder from './FormBuilder/FormBuilder';
import {CalendarIcon, DatePicker} from '@mui/x-date-pickers';
import moment from 'moment';
import CancelIcon from '@mui/icons-material/Close';

const FormStructure = (props) => {
    const {
        name,
        schema,
        templateId,
        accessLevel,
        expirationDate,
        onExpirationDateChange,
        templates,
        formCategories,
        onNameChange,
        handleTemplateChange,
        editMode,
        setSchema,
        isFormArchived,
        formId,
        remindInterval,
        onRemindIntervalChange,
        categories,
        onCategoriesChange,
        componentToEditIndex,
        setComponentToEditIndex
    } = props;
    const nameField = useTextField({
        initialValue: name ?? '',
        validate: (value) => validateFieldLength(value, 128),
        onChange: onNameChange
    });

    return (
        <>
            <Stack
                flexDirection="row"
                flexWrap="wrap"
                gap={2}
                padding={2}
            >
                {
                    !editMode
                        ? <>
                            <TextField
                                sx={{flex: 3}}
                                select
                                value={templateId}
                                label="Form Template"
                                onChange={handleTemplateChange}
                                helperText=" "
                                defaultValue=""
                            >
                                {
                                    templates?.length > 0
                                        ? templates?.map(({id, name}) => (
                                            <MenuItem
                                                key={id}
                                                value={id}
                                            >{name}
                                            </MenuItem>
                                        ))
                                        : <MenuItem value="" disabled>No options</MenuItem>
                                }
                            </TextField>
                            <Divider orientation="vertical" variant="middle" flexItem/>
                        </>
                        : ''
                }
                <TextField
                    sx={{
                        flex: 3,
                        '&.MuiFormControl-root': {
                            margin: 0
                        }
                    }}
                    fullWidth
                    disabled={isFormArchived}
                    label="Form Name"
                    variant="outlined"
                    margin="normal"
                    required={nameField.required}
                    helperText={nameField.helperText ? nameField.helperText : ' '}
                    error={nameField.error}
                    value={nameField.value}
                    onChange={nameField.onChange}
                />
                <Divider orientation="vertical" variant="middle" flexItem/>
                <DatePicker
                    label="Expiration Date"
                    value={expirationDate ? moment(expirationDate) : null}
                    onChange={(newValue) => !expirationDate && onExpirationDateChange(newValue)}
                    onOpen={() => onExpirationDateChange(null)}
                    timezone="UTC"
                    disabled={isFormArchived}
                    format={'D-MMM-YYYY'}
                    minDate={moment(new Date())}
                    slots={{
                        openPickerIcon: expirationDate ? CancelIcon : CalendarIcon,
                    }}
                    slotProps={{
                        field: {
                            readOnly: true,
                            sx: {
                                flex: 3
                            },
                        },
                        textField: {
                            helperText: ' '
                        }
                    }}
                />
                <Divider orientation="vertical" variant="middle" flexItem/>
                <TextField
                    sx={{margin: 0, flex: 2}}
                    required
                    disabled={isFormArchived}
                    label="Remind interval"
                    variant="outlined"
                    value={remindInterval}
                    onChange={(e) => onRemindIntervalChange(e.target.value)}
                    margin="normal"
                    inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                    }}
                    helperText="Enter interval in hours (0 - Disabled)"
                />
                <Divider orientation="vertical" variant="middle" flexItem/>
                <TextField
                    select
                    value={categories}
                    label="Categories"
                    helperText=" "
                    defaultValue=""
                    SelectProps={{
                        multiple: true,
                        renderValue: (selected) => formCategories
                            .filter(x => selected.includes(x.id))
                            .map(x => x.name)
                            .join(', '),
                        MenuProps: {
                            sx: {
                                '& .MuiMenuItem-root': {
                                    whiteSpace: 'unset',
                                    wordBreak: 'break-all',
                                    '& .MuiBox-root': {
                                        whiteSpace: 'unset',
                                        wordBreak: 'break-all'
                                    }
                                },
                            },
                            slotProps: {
                                paper: {
                                    sx: {
                                        maxWidth: {
                                            md: '50vw',
                                            lg: '50vw'
                                        },
                                        maxHeight: '70vh'
                                    }
                                }
                            }
                        }
                    }}
                    sx={{
                        flex: 3
                    }}
                    onChange={onCategoriesChange}
                >
                    {
                        formCategories?.length > 0
                            ? formCategories.map(({id, name}) => (
                                <MenuItem
                                    key={id}
                                    value={id}
                                >
                                    <Checkbox checked={categories.includes(id)}/>
                                    <ListItemText primary={name}/>
                                </MenuItem>
                            ))
                            : <MenuItem value="" disabled>No options</MenuItem>
                    }
                </TextField>
            </Stack>
            <Divider/>
            {schema && <FormBuilder
                schema={schema}
                setSchema={setSchema}
                isFormArchived={isFormArchived}
                accessLevel={accessLevel}
                formId={formId}
                componentToEditIndex={componentToEditIndex}
                setComponentToEditIndex={setComponentToEditIndex}
            />}
        </>
    );
};

FormStructure.propTypes = {
    accessLevel: PropTypes.number,
    onNameChange: PropTypes.func,
    schema: PropTypes.array,
    formRef: PropTypes.object,
    name: PropTypes.string,
    templates: PropTypes.array,
    handleTemplateChange: PropTypes.func,
    setSchema: PropTypes.func,
    templateId: PropTypes.number,
    editMode: PropTypes.bool,
    isFormArchived: PropTypes.bool,
    expirationDate: PropTypes.string,
    remindInterval: PropTypes.number,
    onRemindIntervalChange: PropTypes.func,
    onExpirationDateChange: PropTypes.func,
    formId: PropTypes.number,
    formCategories: PropTypes.array,
    categories: PropTypes.array,
    onCategoriesChange: PropTypes.func,
    componentToEditIndex: PropTypes.number,
    setComponentToEditIndex: PropTypes.func
};

export default FormStructure;