import {apiService} from '../apiService';
import {FORM_ACCESS_LEVELS} from '../../constants/formAccessLevels';

export const formsService = apiService.injectEndpoints({
    endpoints: (builder) => {
        return {
            getForm: builder.query({
                query: (formId) => ({
                    url: `/forms/${formId}`
                }),
                transformResponse: (response) => {
                    const {schema, ...rest} = response;

                    return {schema: JSON.parse(schema), ...rest};
                },
            }),
            getForms: builder.query({
                query: (body) => ({
                    url: '/forms/list',
                    method: 'post',
                    data: body
                }),
                transformErrorResponse: (_) => ({message: 'Failed to retrieve Forms'}),
                providesTags: (_) => [{type: 'Forms', id: 'LIST'}]
            }),
            getAssignmentForms: builder.query({
                query: (formId) => ({
                    url: `/forms/${formId}/assignment-forms`,
                    method: 'get',
                }),
                transformErrorResponse: (_) => ({message: 'Failed to retrieve private Forms'}),
                providesTags: (_) => ['PrivateForms']
            }),
            getPublicForm: builder.query({
                query: ({id, type, queryParams}) =>
                    ({
                        url: type === FORM_ACCESS_LEVELS.publicWithStudentLink
                            ? `/forms/requests/student/${id}`
                            : `/forms/public/${id}`,
                        params: queryParams
                    }),
                transformResponse: (response) => {
                    const {
                        id,
                        name,
                        schema,
                        schoolName,
                        studentForename,
                        hisHer,
                        heShe,
                        himHer,
                        studentFullName,
                        placeholders
                    } = response;
                    return {
                        formId: id,
                        formName: name,
                        schema: schema ? JSON.parse(schema) : {},
                        placeholderValues: placeholders
                            ? JSON.parse(placeholders)
                            : {
                                schoolName,
                                studentForename,
                                studentFullName,
                                hisHer,
                                heShe,
                                himHer
                            },
                    };
                },
                transformErrorResponse: (error) => {
                    const errorMessages = {
                        500: 'Internal server error.',
                        404: 'Could not find the requested form.',
                        410: 'Form was not found or already submitted.'
                    };

                    return {
                        statusText: error.status,
                        message: errorMessages[error.status] ?? 'Something went wrong.'
                    };
                }
            }),
            updateFormStatus: builder.mutation({
                query: (body) => ({
                    url: '/forms/status',
                    method: 'put',
                    data: body,
                }),
                invalidatesTags: [{type: 'Forms', id: 'LIST'}],
            }),
            archiveForm: builder.mutation({
                query: (formId) => ({
                    url: `/forms/archive/${formId}`,
                    method: 'patch'
                }),
                invalidatesTags: [{type: 'Forms', id: 'LIST'}],
            }),
            deleteForm: builder.mutation({
                query: (formId) => ({
                    url: `/forms/delete/${formId}`,
                    method: 'delete'
                }),
                invalidatesTags: [{type: 'Forms', id: 'LIST'}],
            }),
            cloneForm: builder.mutation({
                query: (formId) => ({
                    url: `/forms/clone/${formId}`,
                    method: 'POST'
                }),
                invalidatesTags: [{type: 'Forms', id: 'LIST'}],
            }),
            getFormTemplates: builder.query({
                query: () => ({
                    url: '/forms/templates'
                }),
                transformErrorResponse: (_) => ({message: 'Failed to retrieve Forms'}),
                transformResponse: (response) => (
                    response.templates.map(template => ({
                        id: template.id,
                        name: template.name,
                        schema: JSON.parse(template.schema),
                        type: template.type
                    }))
                )
            }),
            createForm: builder.mutation({
                query: ({schema, ...rest}) => ({
                    url: '/forms',
                    method: 'POST',
                    data: {
                        schema: JSON.stringify(schema),
                        ...rest
                    },
                }),
            }),
            updateForm: builder.mutation({
                query: ({schema, ...rest}) => ({
                    url: '/forms/details',
                    method: 'PUT',
                    data: {
                        schema: JSON.stringify(schema),
                        ...rest
                    },
                }),
            })
        };
    },
});

export const {
    useGetFormQuery,
    useLazyGetFormQuery,
    useGetFormsQuery,
    useGetAssignmentFormsQuery,
    useGetPublicFormQuery,
    useUpdateFormStatusMutation,
    useArchiveFormMutation,
    useDeleteFormMutation,
    useCloneFormMutation,
    useGetFormTemplatesQuery,
    useCreateFormMutation,
    useUpdateFormMutation
} = formsService;
