import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {getRole} from '../../../../../../utility/jwtUtil';
import {ROLES} from '../../../../../../constants/roles';
import DOMPurify from 'dompurify';
import {useSelector} from 'react-redux';

const replaceIfNotNull = (originalText, placeholder, value) => {
    if (value !== null && value !== undefined) {
        const regex = new RegExp(placeholder, 'g');
        return originalText.replace(regex, value);
    }
    return originalText;
}

const RichEditorResultText = ({description, placeholderValues, enableValidation}) => {
    const {account} = useSelector(state => state.application);
    const [text, setText] = useState('');

    useEffect(() => {
        let resultText = description;

        if (placeholderValues) {

            if (getRole() === ROLES.PARENT) {
                resultText = replaceIfNotNull(resultText, '@Parent', placeholderValues.parent);
                resultText = replaceIfNotNull(resultText, '@Title', placeholderValues.parentTitle);
            }

            resultText = replaceIfNotNull(resultText, '@SchoolName', placeholderValues.schoolName);
            resultText = replaceIfNotNull(resultText, '@StudentForename', placeholderValues.studentForename);
            resultText = replaceIfNotNull(resultText, '@StudentFullName', placeholderValues.studentFullName);
            resultText = replaceIfNotNull(resultText, '@HisHer', placeholderValues.hisHer);
            resultText = replaceIfNotNull(resultText, '@HeShe', placeholderValues.heShe);
            resultText = replaceIfNotNull(resultText, '@HimHer', placeholderValues.himHer);
        }

        setText(DOMPurify.sanitize(resultText));
    }, [account, placeholderValues]);
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <span dangerouslySetInnerHTML={{__html: text}}/>
            {enableValidation &&
                <span
                style={{
                    alignSelf: 'flex-start',
                    marginLeft: 5
                }}
                >*</span>
            }
        </div>

    );
};

RichEditorResultText.propTypes = {
    description: PropTypes.string.isRequired,
    readonly: PropTypes.bool.isRequired,
    placeholderValues: PropTypes.object.isRequired,
    enableValidation: PropTypes.bool.isRequired,
};

export default RichEditorResultText;