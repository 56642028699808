import React, {useMemo} from 'react';
import {Box, Button, Chip, Stack, styled} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {formatDate} from '../../../utility/dateUtil';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ContentPasteOffRoundedIcon from '@mui/icons-material/ContentPasteOffRounded';
import {
    useArchiveFormMutation,
    useCloneFormMutation,
    useDeleteFormMutation,
    useGetFormsQuery,
    useUpdateFormStatusMutation
} from '../../../api/services/formsService';
import AdmicityStatus from '../../../shared-components/AdmicityStatus';
import {FORM_STATUSES, FORM_STATUSES_NAMES} from '../../../constants/formStatuses';
import useNotification from '../../../utility/hooks/useNotification';
import AdmicityTableV2 from '../../../shared-components/Table/V2/AdmicityTableV2';
import FILTER_OPERATIONS from '../../../constants/filterOperations';
import withPaginationV2 from '../../../hoc/pagination/withPaginationV2';
import {FORM_ACCESS_LEVELS, FORM_ACCESS_LEVELS_NAMES} from '../../../constants/formAccessLevels';
import DateRangePicker from '../../../shared-components/Table/V2/components/columnFilters/DateRangePicker';
import CircleIcon from '@mui/icons-material/Circle';
import {useGetFormCategoriesQuery} from '../../../api/services/formCategororiesService';
import Tooltip from '@mui/material/Tooltip';

/* eslint-disable react/prop-types */

const StyledCell = styled('div')({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: '24px',
    width: '350px'
});

export const CustomCell = ({cell}) => {
    const value = cell.getValue();
    return (
        <Tooltip title={value} arrow>
            <StyledCell>
                {value}
            </StyledCell>
        </Tooltip>
    );
};

const getFormStatusInfo = (status) =>
    ({
        label: FORM_STATUSES_NAMES[status] ?? 'unknown status',
        color: {
            [FORM_STATUSES.inactive]: 'blueGrey',
            [FORM_STATUSES.active]: 'green',
            [FORM_STATUSES.archived]: 'red',
            [FORM_STATUSES.expired]: 'red'
        }[status] ?? 'grey'
    });

const Table = withPaginationV2(AdmicityTableV2, {func: useGetFormsQuery});

const Forms = () => {
    const [updateForm] = useUpdateFormStatusMutation();
    const [deleteForm] = useDeleteFormMutation();
    const [cloneForm] = useCloneFormMutation();
    const [archiveForm] = useArchiveFormMutation();
    const {data: formCategories} = useGetFormCategoriesQuery();
    const navigate = useNavigate();
    const {showErrorNotification} = useNotification();

    const makeFormLive = async (selectedRow) => {
        const result = await updateForm({id: selectedRow.id});

        if (result.error) {
            showErrorNotification(result.error.data);
        }
    };

    const editForm = (selectedRow) => {
        navigate(`edit/${selectedRow.id}`);
    };

    const viewResponses = (selectedRow) => {
        navigate(`responses/${selectedRow.id}`);
    };

    const columns = useMemo(() => [
        {
            accessorKey: 'name',
            header: 'Name',
            Cell: CustomCell,
        },
        {
            accessorKey: 'assignedGroups',
            header: 'Group',
            Cell: CustomCell,
            enableColumnFilter: false
        },
        {
            accessorKey: 'accessLevel',
            header: 'Type',
            filterVariant: 'select',
            filterFn: FILTER_OPERATIONS.EQUALS,
            filterSelectOptions: Object.values(FORM_ACCESS_LEVELS).map(levelId => ({
                value: levelId,
                label: FORM_ACCESS_LEVELS_NAMES[levelId]
            })),
            Cell: ({cell}) => FORM_ACCESS_LEVELS_NAMES[cell.getValue()] ?? 'unknown',
        },
        {
            accessorKey: 'status',
            header: 'Status',
            filterVariant: 'multi-select',
            filterFn: FILTER_OPERATIONS.MULTISELECT,
            filterSelectOptions: Object.values(FORM_STATUSES)
                .filter(statusId => statusId !== FORM_STATUSES.deleted)
                .map(statusId => ({
                    value: statusId,
                    label: FORM_STATUSES_NAMES[statusId]
                })),
            Cell: ({cell}) => cell.getValue() && <AdmicityStatus {...getFormStatusInfo(cell.getValue())}/>,
            muiFilterTextFieldProps: {
                sx: {
                    minWidth: 350
                }
            }
        },
        {
            accessorKey: 'expirationDate',
            header: 'Expiration Date',
            Cell: ({cell}) => cell.getValue() ? formatDate(cell.getValue()) : '',
            Filter: DateRangePicker,
            filterFn: FILTER_OPERATIONS.BETWEEN_INCLUSIVE,
        },
        {
            accessorKey: 'categories',
            header: 'Category',
            filterVariant: 'multi-select',
            filterFn: FILTER_OPERATIONS.MULTISELECT,
            minSize: 350,
            filterSelectOptions: formCategories
                ?.map(({id, name}) => ({
                    value: id,
                    label: name
                })) ?? [],
            Cell: ({cell}) => {
                const categories = cell.getValue();

                if (!categories) {
                    return '';
                }

                if (categories.length === 1) {
                    const {color, name} = categories[0];
                    return (
                        <Chip
                            icon={<CircleIcon fontSize="small" style={{color}}/>}
                            label={name}
                            variant="outlined"
                        />
                    );
                }

                return (
                    <Stack flexDirection="row" gap={1} flexWrap="wrap">
                        {categories.map(({color, name}, index) => (
                            <Tooltip key={index} title={name} arrow>
                                <CircleIcon fontSize="small" style={{color}}/>
                            </Tooltip>
                        ))}
                    </Stack>
                );
            },
        },
        {
            accessorKey: 'createdAt',
            header: 'Created At',
            Cell: ({cell}) => formatDate(cell.getValue()),
            Filter: DateRangePicker,
            filterFn: FILTER_OPERATIONS.BETWEEN_INCLUSIVE,
        },
        {
            accessorKey: 'createdBy',
            header: 'Created By',
            enableColumnFilter: false
        }
    ], [formCategories]);

    /* eslint-enable react/prop-types */

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                minHeight: '100%'
            }}
        >
            <div>
                <Button
                    variant="contained"
                    onClick={() => navigate('new')}
                    sx={{mb: 2, mt: 2}}
                    startIcon={<AddCircleOutlineIcon/>}
                >
                    Create new Form
                </Button>
            </div>
            <Table
                columns={columns}
                initialState={{
                    columnFilters: [
                        {
                            id: 'status',
                            value: [FORM_STATUSES.active, FORM_STATUSES.inactive, FORM_STATUSES.expired]
                        }
                    ]
                }}
                tableProps={{
                    enableSorting: false,
                    enableFullScreenToggle: false,
                    enableColumnResizing: false
                }}
                rowActions={[
                    {
                        label: 'Edit Form',
                        action: editForm
                    },
                    {
                        disabled: (selectedRow) => selectedRow && selectedRow.status !== FORM_STATUSES.inactive,
                        label: 'Make form live',
                        action: makeFormLive
                    },
                    {
                        disabled: (selectedRow) => selectedRow && selectedRow.status === FORM_STATUSES.inactive,
                        label: 'View responses',
                        action: viewResponses
                    },
                    {
                        disabled: (selectedRow) => selectedRow && selectedRow.status === FORM_STATUSES.archived,
                        label: 'Archive form',
                        action: async (selectedRow) => {
                            await archiveForm(selectedRow.id);
                        }
                    },
                    {
                        label: 'Delete form',
                        action: async (selectedRow) => await deleteForm(selectedRow.id)
                    },
                    {
                        label: 'Clone form',
                        action: async (selectedRow) => await cloneForm(selectedRow.id)
                    },

                ]}
                noRowsOverlay={{
                    icon: <ContentPasteOffRoundedIcon/>,
                    text: 'No forms has been added'
                }}
            />
        </Box>);
};

export default Forms;
