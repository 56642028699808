const mergeArraysByKey = (actual, original, key = 'id') => {
    const actualMap = new Map(actual.map(item => [item[key], item]));
    const result = original.map(item => {
        if (actualMap.has(item[key])) {
            const actualItem = actualMap.get(item[key]);

            actualMap.delete(item[key]);

            return actualItem;
        } else {
            return {...item, isDeleted: true};
        }
    });

    actualMap.forEach(item => {
        result.push({...item, isNew: true});
    });

    return result;
};

export default mergeArraysByKey;