import React, {useEffect, useRef, useState} from 'react';
import {Box, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import RequiredCheckbox from './Shared/RequiredCheckbox';
import RichEditor from './Shared/RichEditor';
import RichEditorResultText from './Shared/RichEditorResultText';
import {stripHtmlTags} from './AdditionalNotes';

const TextBox = ({
                     index,
                     id,
                     value,
                     onChange,
                     description,
                     isEditMode,
                     enableValidation,
                     isFormArchived,
                     readonly,
                     placeholderValues,
                     accessLevel,
                     onUpdateDescription,
                     componentToEditIndex,
                     submitMode,
                 }) => {
    const [currentValue, setCurrentValue] = useState(description);
    const prevPropRef = useRef();

    useEffect(() => {
        setCurrentValue(description)
    }, [description]);

    useEffect(() => {
        if (prevPropRef.current === index && componentToEditIndex !== prevPropRef.current) {
            onUpdateDescription({index, description: stripHtmlTags(currentValue), formattedDescription: currentValue});
        }
        prevPropRef.current = componentToEditIndex;
    }, [componentToEditIndex]);

    return (<Box>
        {isEditMode
            ? <>
                <RichEditor value={description} onChange={onChange} index={index} isEditMode={isEditMode}
                            isFormLive={isFormArchived} accessLevel={accessLevel} currentDescription={currentValue}
                            setCurrentDescription={setCurrentValue}/>
                <RequiredCheckbox enableValidation={enableValidation} index={index} onChange={onChange}
                                  isFormArchived={isFormArchived}/>
            </>
            : <>
                <RichEditorResultText description={description} placeholderValues={placeholderValues}
                                      readonly={readonly} enableValidation={enableValidation}/>
                <TextField fullWidth value={value} required={enableValidation} multiline disabled={!submitMode}
                           onChange={e => onChange({id, value: e.target.value})}/>
            </>}
    </Box>);
};

TextBox.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    index: PropTypes.number,
    description: PropTypes.string,
    enableValidation: PropTypes.bool,
    onChange: PropTypes.func,
    isEditMode: PropTypes.bool,
    isFormArchived: PropTypes.bool,
    readonly: PropTypes.bool,
    placeholderValues: PropTypes.object,
    accessLevel: PropTypes.number,
    onUpdateDescription: PropTypes.func,
    componentToEditIndex: PropTypes.number,
    submitMode: PropTypes.bool,
};

export default TextBox;