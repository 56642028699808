import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, Divider, Drawer, Stack, useTheme} from '@mui/material';
import PropTypes from 'prop-types';
import AdmicityGroupFilterTree from './AdmicityGroupFilterTree';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import Typography from '@mui/material/Typography';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import IconButton from '@mui/material/IconButton';
import useDynamicHeight from '../utility/hooks/useDynamicHeight';
import {useCreateGroupMutation, useDeleteGroupMutation, useUpdateGroupMutation} from '../api/services/groupService';
import GroupAddRoundedIcon from '@mui/icons-material/GroupAddRounded';
import CreateOrUpdateGroupDialog from './CreateOrUpdateGroupDialog';
import useNotification from '../utility/hooks/useNotification';

const groupFiltersDrawerWidth = 350;

const AdmicityDrawer = ({filters, filterId, defaultSelected, onApply, onFilterDeleted}) => {
    const theme = useTheme();
    const [openGroupFilters, setOpenGroupFilters] = useState(false);
    const [isClosing, setIsClosing] = React.useState(false);
    const [selectedNode, setSelectedNode] = useState(null);
    const [openCreateSubgroupDialog, setOpenCreateSubgroupDialog] = useState(false);
    const [openEditSubgroupDialog, setOpenEditSubgroupDialog] = useState(false);
    const parentRef = useRef(null);
    const freeSpaceHeight = useDynamicHeight(parentRef);
    const [createGroup, createGroupResult] = useCreateGroupMutation();
    const [updateGroup, updateGroupResult] = useUpdateGroupMutation();
    const [deleteGroup] = useDeleteGroupMutation();
    const {showSuccessNotification, showDetailedErrorNotification} = useNotification();

    useEffect(() => {
        const isSuccess = createGroupResult.isSuccess || updateGroupResult.isSuccess;
        const isError = createGroupResult.isError || updateGroupResult.isError;
        const error = createGroupResult.error || updateGroupResult.error;

        if (isSuccess) {
            showSuccessNotification('Group saved');
            handleDialogClose();
        } else if (isError) {
            showDetailedErrorNotification('Failed to save group', error.data.validationErrors);
        }
    }, [createGroupResult, updateGroupResult]);

    const handleDrawerClose = () => {
        setIsClosing(true);
        setOpenGroupFilters(false);
    };

    const handleDrawerTransitionEnd = () => {
        setIsClosing(false);
    };

    const handleDrawerToggle = () => {
        if (!isClosing) {
            setOpenGroupFilters(!openGroupFilters);
        }
    };

    const handleFilterChange = (nodeId, paths) => {
        onApply(nodeId, paths);
        handleDrawerClose();
    };

    const handleDialogClose = () => {
        setOpenCreateSubgroupDialog(false);
        setOpenEditSubgroupDialog(false);
        setSelectedNode(null);
    };

    const treeItemActions = [
        {
            label: 'Add subgroup',
            onClick: node => {
                setOpenCreateSubgroupDialog(true);
                setSelectedNode(node);
            }
        },
        {
            label: 'Edit',
            onClick: node => {
                setOpenEditSubgroupDialog(true);
                setSelectedNode(node);
            }
        },
        {
            label: 'Delete',
            onClick: async node => {
                await deleteGroup({groupFilterId: node.nodeId});

                if (onFilterDeleted) {
                    onFilterDeleted(node.nodeId);
                }
            }
        }
    ];

    const handleSaveGroup = async (body) => {
        const func = openCreateSubgroupDialog
            ? createGroup
            : openEditSubgroupDialog
                ? updateGroup
                : (_) => {
                };
        const {filterId, ...rest} = body;
        const payload = {...rest};

        if (openCreateSubgroupDialog) {
            payload.parentGroupFilterId = selectedNode?.nodeId;
            payload.studentGroupAssignmentFilterId = filterId && filterId !== '' ? filterId : null;
        }

        if (openEditSubgroupDialog) {
            payload.groupFilterId = selectedNode?.nodeId;
            payload.studentGroupAssignmentFilterId = filterId && filterId !== '' ? filterId : null;
        }

        await func(payload);
    };

    return (
        <>
            <Box
                ref={parentRef}
                position="relative"
            >
                <Box sx={{
                    display: {
                        xs: 'block',
                        sm: 'block',
                        md: 'block',
                        lg: 'none',
                        xl: 'none',
                    }
                }}>
                    <div>
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleDrawerToggle}
                            startIcon={<FilterListRoundedIcon/>}
                            sx={{marginRight: 2, marginBottom: 2}}
                        >
                            Group Filters
                        </Button>
                    </div>
                    <Drawer
                        variant="temporary"
                        open={openGroupFilters}
                        onTransitionEnd={handleDrawerTransitionEnd}
                        onClose={handleDrawerClose}
                        ModalProps={{keepMounted: true}}
                        sx={{
                            display: {
                                xs: 'block',
                                sm: 'block',
                                md: 'block',
                                lg: 'none',
                                xl: 'none'
                            },
                            '& .MuiDrawer-paper': {
                                boxSizing: 'border-box',
                                width: {
                                    xs: '100%',
                                    sm: '70%',
                                    md: '50%'
                                }
                            }
                        }}
                    >
                        <Box sx={{flexGrow: 1, overflowY: 'auto'}}>
                            <Stack
                                margin={2}
                                direction="row"
                                justifyContent="space-between"
                            >
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                >
                                    <IconButton onClick={() => handleDrawerClose()}>
                                        <ArrowBackRoundedIcon/>
                                    </IconButton>
                                    <Typography marginLeft={1} color="text.secondary">Group Filters</Typography>
                                </Stack>
                                <Button
                                    startIcon={<GroupAddRoundedIcon/>}
                                    onClick={() => setOpenCreateSubgroupDialog(true)}
                                >Add Group</Button>
                            </Stack>
                            <Divider/>
                            <AdmicityGroupFilterTree
                                expandMinLevel={1}
                                data={filters}
                                selected={filterId || defaultSelected}
                                onChange={handleFilterChange}
                                itemActions={treeItemActions}
                            />
                        </Box>
                        <Divider/>
                    </Drawer>
                </Box>
                <Box sx={{
                    width: groupFiltersDrawerWidth,
                    display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'none',
                        lg: 'block',
                        xl: 'block',
                    }
                }}>
                    <Drawer
                        variant="permanent"
                        sx={{
                            display: {
                                xs: 'none',
                                sm: 'none',
                                md: 'none',
                                lg: 'block',
                                xl: 'block',
                            },
                            '& .MuiDrawer-paper': {
                                display: 'flex',
                                boxSizing: 'border-box',
                                flexDirection: 'column',
                                border: `1px solid ${theme.palette.divider}`,
                                borderRadius: 1,
                                position: 'relative',
                                width: '100%',
                                maxHeight: `${freeSpaceHeight}px`
                            },
                        }}
                        open
                    >
                        <Box padding={1}>
                            <Button
                                startIcon={<GroupAddRoundedIcon/>}
                                onClick={() => setOpenCreateSubgroupDialog(true)}
                            >Add Group</Button>
                        </Box>
                        <Divider/>
                        <Box sx={{flexGrow: 1, overflowY: 'auto'}} marginTop={1}>
                            <AdmicityGroupFilterTree
                                expandMinLevel={1}
                                data={filters}
                                selected={filterId || defaultSelected}
                                onChange={handleFilterChange}
                                itemActions={treeItemActions}
                            />
                        </Box>
                    </Drawer>
                </Box>
            </Box>
            <CreateOrUpdateGroupDialog
                open={openCreateSubgroupDialog || openEditSubgroupDialog}
                title={openCreateSubgroupDialog ? 'Create new group' : 'Update group'}
                group={
                    selectedNode
                        ? openCreateSubgroupDialog
                            ? {parentGroupFilterId: selectedNode.nodeId}
                            : {
                                name: selectedNode.label,
                                parentGroupFilterId: selectedNode.basedOnGroupFilterId,
                                students: selectedNode.studentIds,
                                filterId: selectedNode.studentGroupAssignmentFilterId
                            }
                        : {}
                }
                groupFilters={filters}
                onClose={handleDialogClose}
                onSave={handleSaveGroup}
            />
        </>
    );
};

AdmicityDrawer.propTypes = {
    filters: PropTypes.array,
    filterId: PropTypes.number,
    onApply: PropTypes.func,
    onFilterDeleted: PropTypes.func,
    defaultSelected: PropTypes.string
};

export default AdmicityDrawer;