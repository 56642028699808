import {apiService} from '../apiService';

export const formAssignmentsService = apiService.injectEndpoints({
    endpoints: (builder) =>
        ({
            getFormAssignments: builder.query({
                query: (body) => ({
                    url: `/forms/${body.formId}/assignments`,
                    method: 'POST',
                    data: body,
                }),
                transformErrorResponse: (_) => ({message: 'Failed to retrieve form assignments'}),
                providesTags: (_) => [{type: 'FormAssignments', id: 'LIST'}]
            }),
            getFormStudentsAssignments: builder.query({
                query: (body) => ({
                    url: `/forms/${body.formId}/assignments/students`,
                    method: 'POST',
                    data: body,
                }),
                transformErrorResponse: (_) => ({message: 'Failed to retrieve form student assignments'}),
                providesTags: (_) => ['FormStudentAssignments']
            }),
            createFormAssignment: builder.mutation({
                query: (body) => ({
                    url: '/forms/assignment',
                    method: 'POST',
                    data: body,
                }),
                invalidatesTags: [{type: 'FormAssignments', id: 'LIST'}],
            }),
            getFormQuestionAssignment: builder.query({
                query: (formId) => ({
                    url: `/forms/${formId}/question-assignment`,
                    method: 'GET'
                }),
                transformErrorResponse: (_) => ({message: 'Failed to retrieve form QUESTION assignments'}),
                providesTags: (_) => [{type: 'FormQuestionAssignments', id: 'LIST'}]
            }),
            getRelatedFormQuestionAssignment: builder.query({
                query: (formId) => ({
                    url: `/forms/${formId}/question-assignment/related`,
                    method: 'GET'
                }),
                transformErrorResponse: (_) => ({message: 'Failed to retrieve form QUESTION assignments'}),
                providesTags: (_) => [{type: 'FormQuestionAssignments', id: 'LIST'}]
            }),
            createFormQuestionAssignment: builder.mutation({
                query: (body) => ({
                    url: '/forms/question',
                    method: 'POST',
                    data: body,
                }),
                invalidatesTags: [{type: 'FormQuestionAssignments', id: 'LIST'}],
            }),
            deleteFormQuestionAssignment: builder.mutation({
                query: (id) => ({
                    url: `/forms/question/${id}`,
                    method: 'DELETE'
                }),
                invalidatesTags: [{type: 'FormQuestionAssignments', id: 'LIST'}],
            }),
            deleteFormAssignments: builder.mutation({
                query: (body) => ({
                    url: '/forms/assignment',
                    method: 'DELETE',
                    data: body,
                }),
                invalidatesTags: [{type: 'FormAssignments', id: 'LIST'}],
            }),
            deleteFormStudentsAssignments: builder.mutation({
                query: (body) => ({
                    url: '/forms/assignment/students',
                    method: 'DELETE',
                    data: body,
                }),
                invalidatesTags: ['FormStudentAssignments']
            }),
            createFormStudentAssignment: builder.mutation({
                query: (body) => ({
                    url: '/forms/assignment/student',
                    method: 'POST',
                    data: body,
                }),
                invalidatesTags: ['FormStudentAssignments']
            }),
            getExternalUserFormAssignments: builder.query({
                query: (body) => ({
                    url: `/forms/${body.formId}/assignments/external`,
                    data: body,
                }),
                transformErrorResponse: (_) => ({message: 'Failed to retrieve external user form assignments'}),
                providesTags: (_) => [{type: 'ExternalUserFormAssignments', id: 'LIST'}]
            }),
            createExternalUserFormAssignments: builder.mutation({
                query: (body) => ({
                    url: '/forms/assignments/external',
                    method: 'POST',
                    data: body,
                }),
                invalidatesTags: [{type: 'ExternalUserFormAssignments', id: 'LIST'}],
            }),
            deleteExternalUserFormAssignments: builder.mutation({
                query: (body) => ({
                    url: '/forms/assignments/external',
                    method: 'DELETE',
                    data: body,
                }),
                invalidatesTags: [{type: 'ExternalUserFormAssignments', id: 'LIST'}],
            }),
        }),
});

export const {
    useGetFormAssignmentsQuery,
    useGetFormStudentsAssignmentsQuery,
    useCreateFormAssignmentMutation,
    useCreateFormQuestionAssignmentMutation,
    useDeleteFormQuestionAssignmentMutation,
    useGetFormQuestionAssignmentQuery,
    useGetRelatedFormQuestionAssignmentQuery,
    useDeleteFormAssignmentsMutation,
    useDeleteFormStudentsAssignmentsMutation,
    useCreateFormStudentAssignmentMutation,
    useGetExternalUserFormAssignmentsQuery,
    useCreateExternalUserFormAssignmentsMutation,
    useDeleteExternalUserFormAssignmentsMutation
} = formAssignmentsService;
