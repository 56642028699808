import useTextField from '../utility/hooks/useTextField';
import {validateFieldLength} from '../utility/validationUtil';
import React, {useEffect, useState} from 'react';
import {useGetStudentFiltersQuery} from '../api/services/studentService';
import AdmicityDialog from './AdmicityDialog';
import {Autocomplete, Checkbox, TextField} from '@mui/material';
import AdmicityGroupFilterSelect from './AdmicityGroupFilterSelect';
import PropTypes from 'prop-types';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

const CreateOrUpdateGroupDialog = (
    {
        open,
        title,
        group,
        onClose,
        onSave,
        groupFilters
    }) => {
    const nameField = useTextField({
        initialValue: group.name ?? '',
        validate: (value) => validateFieldLength(value, 256)
    });
    const [filterId, setFilterId] = useState(group.filterId ?? '');
    const [students, setStudents] = useState([]);
    const [applications, setApplications] = useState([]);
    const {
        data: studentsData = {
            applications: [],
            students: []
        }
    } = useGetStudentFiltersQuery({filterId: group.parentGroupFilterId}, {skip: !open});

    useEffect(() => {
        if (!open) {
            nameField.reset();
            setFilterId('');
            setApplications([]);
            setStudents([]);
        } else {
            nameField.setValue(group.name ?? '');
            setFilterId(group.filterId);
        }
    }, [open]);

    useEffect(() => {
        if (studentsData && group.students?.length > 0) {
            setStudents(studentsData.students.filter(x => group.students.includes(x.id)));
            setApplications(studentsData.applications.filter(x => group.students.includes(x.id)));
        }
    }, [studentsData, group]);

    const studentsAndApplicantsFields = [
        {
            label: 'Students',
            values: studentsData.students,
            value: students,
            onChange: (e, newValue) => setStudents(newValue)
        },
        {
            label: 'Applicants',
            values: studentsData.applications,
            value: applications,
            onChange: (e, newValue) => setApplications(newValue)
        }
    ];

    return (
        <AdmicityDialog
            handleClose={onClose}
            title={title}
            open={open}
            maxWidth="md"
            actions={[
                {
                    label: 'Cancel',
                    onClick: onClose
                },
                {
                    label: 'Save',
                    disabled: nameField.error || !nameField.value,
                    onClick: () => onSave({
                        filterId,
                        applicantIds: applications.map(x => x.id),
                        studentIds: students.map(x => x.id),
                        groupName: nameField.value
                    })
                }
            ]}
        >
            <TextField
                required
                fullWidth
                label="Group Name"
                variant="outlined"
                margin="normal"
                {...nameField}
            />
            <AdmicityGroupFilterSelect
                sx={{
                    marginTop: 1,
                    width: '100%'
                }}
                data={groupFilters}
                value={filterId}
                initialValue={group.filterId}
                onChange={setFilterId}
            />
            {
                studentsAndApplicantsFields.map(x => (
                    <Autocomplete
                        key={x.label}
                        multiple
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.label}
                        limitTags={5}
                        renderOption={(props, option, {selected}) => {
                            /* eslint-disable react/prop-types */
                            const {key, ...optionProps} = props;
                            return (
                                <li key={option.key} {...optionProps}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        checked={selected}
                                        sx={{
                                            marginRight: 1
                                        }}
                                    />
                                    {option.label}
                                </li>
                            );
                            /* eslint-enable react/prop-types */
                        }}
                        renderInput={(params) => <TextField
                            {...params}
                            label={x.label}
                            margin={'normal'}
                        />}
                        options={x.values}
                        value={x.value}
                        onChange={x.onChange}
                    />
                ))
            }

        </AdmicityDialog>
    );
};

CreateOrUpdateGroupDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    group: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    groupFilters: PropTypes.array.isRequired
};

export default CreateOrUpdateGroupDialog;
