import {Box, Button, Paper, Stack, Typography} from '@mui/material';
import RichEditor from './Shared/RichEditor';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import RichEditorResultText from './Shared/RichEditorResultText';
import {stripHtmlTags} from './AdditionalNotes';
import {useDropzone} from 'react-dropzone';
import useNotification from '../../../../../utility/hooks/useNotification';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileRounded';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import RequiredCheckbox from './Shared/RequiredCheckbox';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';

const MAX_FORM_FILE_UPLOAD_SIZE_IN_MB = 25;
const FileUploader = ({
                          index,
                          id,
                          value,
                          onChange,
                          description,
                          isEditMode,
                          enableValidation,
                          isFormArchived,
                          readonly,
                          placeholderValues,
                          accessLevel,
                          onUpdateDescription,
                          componentToEditIndex,
                          handleDownloadAttachment
                      }) => {
    const [componentDescription, setComponentDescription] = useState(description);
    const prevPropRef = useRef();
    const {showErrorNotification} = useNotification();

    const onDrop = useCallback((acceptedFiles) => {
        onChange({
            id,
            value: acceptedFiles[0]
        });
    }, []);

    const onDropRejected = useCallback((rejections) => {
        if (rejections.some(e => e.errors.some(error => error.code === 'file-too-large'))) {
            showErrorNotification(`The size of the file exceeds the limit of ${MAX_FORM_FILE_UPLOAD_SIZE_IN_MB}MB.`);
        }
    }, []);

    const {getInputProps, open} = useDropzone({
        onDrop,
        onDropRejected,
        noClick: true,
        noKeyboard: true,
        accept: {
            'image/*': [],
            'application/msword': [],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
            'application/pdf': [],
            'application/vnd.ms-excel': [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
            'application/zip': [],
            'application/x-zip-compressed': [],
            'application/vnd.ms-powerpoint': [],
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': []
        },
        multiple: false,
        maxSize: MAX_FORM_FILE_UPLOAD_SIZE_IN_MB * 1024 * 1024
    });

    useEffect(() => {
        setComponentDescription(description);
    }, [description]);

    useEffect(() => {
        if (prevPropRef.current === index && componentToEditIndex !== prevPropRef.current) {
            onUpdateDescription({
                index,
                description: stripHtmlTags(componentDescription),
                formattedDescription: componentDescription
            });
        }
        prevPropRef.current = componentToEditIndex;
    }, [componentToEditIndex]);

    return (
        <Box display="flex" flexDirection="column" gap={2}>
            {
                isEditMode
                    ? <RichEditor
                        value={description}
                        onChange={onChange}
                        index={index}
                        isFormLive={isFormArchived}
                        accessLevel={accessLevel}
                        currentDescription={componentDescription}
                        setCurrentDescription={setComponentDescription}/>
                    : <RichEditorResultText
                        description={description}
                        placeholderValues={placeholderValues}
                        readonly={readonly}
                        enableValidation={enableValidation}/>
            }
            {
                value
                    ? <Paper variant="outlined" sx={{width: 'fit-content'}}>
                        <Stack
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center"
                            paddingTop={0.5}
                            paddingBottom={0.5}
                            paddingLeft={1}
                            paddingRight={1}
                            gap={1}
                        >
                            <InsertDriveFileRoundedIcon
                                fontSize="small"
                                sx={{color: 'primary.main'}}
                            />
                            <Typography variant="body2">{value.name}</Typography>
                            {
                                !readonly
                                    ? <IconButton
                                        size="small"
                                        sx={{padding: 0.25}}
                                        onClick={() => onChange({id, value: null})}
                                    >
                                        <CloseIcon fontSize="small"/>
                                    </IconButton>
                                    : value.fileKey && handleDownloadAttachment
                                        ? <IconButton
                                            size="small"
                                            onClick={() => handleDownloadAttachment(value.fileKey)}
                                        ><DownloadRoundedIcon fontSize="small"/></IconButton>
                                        : null
                            }

                        </Stack>
                    </Paper>
                    : !readonly
                        ? <Stack flexDirection="row" alignItems="center" gap={1}>
                            <input {...getInputProps()} />
                            <Button onClick={open} startIcon={<UploadFileOutlinedIcon/>} variant="outlined">Add
                                File</Button>
                            <Typography variant="body2" color="text.secondary"> — Max file
                                size {MAX_FORM_FILE_UPLOAD_SIZE_IN_MB} MB</Typography>
                        </Stack>
                        : null
            }
            {
                isEditMode && <RequiredCheckbox
                    enableValidation={enableValidation}
                    index={index}
                    onChange={onChange}
                    isFormArchived={isFormArchived}/>
            }
        </Box>
    );
};

FileUploader.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    index: PropTypes.number,
    options: PropTypes.array,
    description: PropTypes.string,
    enableValidation: PropTypes.bool,
    onChange: PropTypes.func,
    isEditMode: PropTypes.bool,
    isFormArchived: PropTypes.bool,
    relatedComponent: PropTypes.object,
    readonly: PropTypes.bool,
    placeholderValues: PropTypes.object,
    accessLevel: PropTypes.number,
    onUpdateDescription: PropTypes.func,
    componentToEditIndex: PropTypes.number,
    handleDownloadAttachment: PropTypes.number,
};

export default FileUploader;