import {useGetGroupFiltersQuery} from '../../../../api/services/groupService';
import {useGetStudentFiltersQuery} from '../../../../api/services/studentService';
import {
    useGetFormAssignmentsQuery,
    useGetFormStudentsAssignmentsQuery
} from '../../../../api/services/formAssignmentsService';
import FORM_AUDIENCE_TYPE from '../../../../constants/FormAudienceType';
import React, {useEffect, useState} from 'react';
import {SettingsSection} from './SettingsSection';
import {Box, Button, Checkbox, FormControlLabel, Stack, Typography} from '@mui/material';
import EmailsInput from '../../../../shared-components/EmailsInput';
import AdmicityGroupFilterSelect from '../../../../shared-components/AdmicityGroupFilterSelect';
import AdmicityTableV2 from '../../../../shared-components/Table/V2/AdmicityTableV2';
import StudentsAutocomplete from './StudentsAutocomplete';
import mergeArraysByKey from '../../../../utility/mergeArraysByKey';

/* eslint-disable react/prop-types */

const RegularFormSettings = (
    {
        audience,
        formId,
        settings,
        strictRecipientsFiltering,
        isFormLive,
        externalUsers,
        onSettingsChange,
        onSetStrictRecipientsFiltering
    }) => {
    const {
        groupFilters,
        studentFilters,
        applicationFilters,
        externalUserEmailAssignments
    } = settings;
    const [originalSettings, setOriginalSettings] = useState({
        groupFilters: [],
        studentFilters: [],
        applicationFilters: [],
        externalUserEmailAssignments: []
    });
    const [filter, setFilter] = useState(null);
    const {data} = useGetGroupFiltersQuery({}, {skip: !audience});
    const {data: studentsData} = useGetStudentFiltersQuery({}, {skip: !audience});
    const {data: formAssignmentData} = useGetFormAssignmentsQuery({formId});
    const {data: studentFormAssignmentData} = useGetFormStudentsAssignmentsQuery({formId});

    useEffect(() => {
        if (formAssignmentData) {
            const groupFilters = formAssignmentData.map(x => ({
                id: x.filterId,
                displayName: x.displayName,
                groupId: x.id
            }));

            onSettingsChange(prev => ({
                ...prev,
                groupFilters
            }));
            setOriginalSettings(prev => ({...prev, groupFilters}));
        }
    }, [formAssignmentData]);

    useEffect(() => {
        if (studentFormAssignmentData && studentsData) {
            const studentFilters = studentFormAssignmentData
                .filter(filter => studentsData.students.some(student => filter.studentId === student.id))
                .map(x => ({
                    id: x.studentId,
                    label: x.displayName,
                    assignmentId: x.id
                }));
            const applicationFilters = studentFormAssignmentData
                .filter(filter => studentsData.applications.some(application => filter.studentId === application.id))
                .map(x => ({
                    id: x.studentId,
                    label: x.displayName,
                    assignmentId: x.id
                }));

            onSettingsChange(prev => ({...prev, studentFilters, applicationFilters}));
            setOriginalSettings(prev => ({...prev, studentFilters, applicationFilters}));
        }
    }, [studentFormAssignmentData, studentsData]);

    useEffect(() => {
        if (externalUsers) {
            onSettingsChange(prev => ({
                ...prev,
                externalUserEmailAssignments: externalUsers
            }));
            setOriginalSettings(prev => ({
                ...prev,
                externalUserEmailAssignments: externalUsers
            }));
        }
    }, [externalUsers]);

    const handleAddRecipient = async () => {
        const filters = [...settings.groupFilters,
            {
                id: filter.filterId,
                displayName: filter.paths.map(x => x.name).join(' - '),
            }];

        onSettingsChange(prev => ({
            ...prev,
            groupFilters: mergeArraysByKey(filters, settings.groupFilters)
        }));
        setFilter(null);
    };

    return (
        <Box display="flex" flexDirection="column" gap={2}>
            {
                audience === FORM_AUDIENCE_TYPE.externalUsers
                    ? <SettingsSection>
                        <Box>
                            <Typography variant="subtitle1" fontWeight="bold">External users</Typography>
                            <Typography variant="subtitle2">Type one or more user emails</Typography>
                        </Box>
                        <EmailsInput
                            values={externalUserEmailAssignments.filter(x => !x.isDeleted)}
                            onChange={emails => onSettingsChange(prev => ({
                                ...prev,
                                externalUserEmailAssignments: mergeArraysByKey(
                                    emails,
                                    originalSettings.externalUserEmailAssignments,
                                    'email')
                            }))}
                            onBlur={email => onSettingsChange(prev => ({
                                ...prev,
                                externalUserEmailAssignments: mergeArraysByKey(
                                    [...settings.externalUserEmailAssignments, {email}],
                                    originalSettings.externalUserEmailAssignments,
                                    'email')
                            }))}
                        />
                    </SettingsSection>
                    : null
            }
            <SettingsSection>
                <Box>
                    <Typography variant="subtitle1" fontWeight="bold">Select Student Groups</Typography>
                    <Typography variant="subtitle2">Choose one or more student groups to associate
                        with
                        the form</Typography>
                </Box>
                <Box>
                    <Stack alignItems="center" flexDirection="row" gap={2}>
                        <AdmicityGroupFilterSelect
                            sx={{
                                flex: 1
                            }}
                            data={data?.modules}
                            value={filter?.filterId || ''}
                            onChange={(filterId, paths) => setFilter({filterId, paths})}
                        />
                        <Button
                            onClick={handleAddRecipient}
                            variant="contained"
                            disabled={!filter}
                        >
                            Add
                        </Button>
                    </Stack>
                    <FormControlLabel
                        sx={{
                            height: 46,
                            marginTop: 1
                        }}
                        control={
                            <Checkbox
                                checked={strictRecipientsFiltering}
                                onChange={async (e) =>
                                    await onSetStrictRecipientsFiltering(e.target.checked)
                                }
                                disabled={isFormLive}
                            />
                        }
                        label="Must appear in all these groups"
                    />
                </Box>
            </SettingsSection>
            <AdmicityTableV2
                columns={[
                    {
                        accessorKey: 'displayName',
                        header: 'Group Name'
                    }
                ]}
                toolbarActions={[
                    {
                        type: 'button',
                        label: 'Delete selected groups',
                        size: 'small',
                        enableOnRowSelect: true,
                        onClick: (rows) => {
                            const filters = settings.groupFilters.filter(filter => !rows.some(row => row.id === filter.id));

                            onSettingsChange(prev => ({
                                ...prev,
                                groupFilters: mergeArraysByKey(filters, originalSettings.groupFilters)
                            }));
                        }
                    }
                ]}
                data={groupFilters.filter(filter => !filter.isDeleted)}
                tableProps={{
                    enableRowSelection: true,
                    enableSorting: false,
                    enableFilters: false,
                    enableHiding: false,
                    enableFullScreenToggle: false,
                    enableColumnResizing: false,
                    enableBottomToolbar: false,
                    muiTableBodyProps: {
                        sx: {
                            '& :last-child td': {
                                borderBottom: 0,
                            },
                        }
                    },
                    muiTablePaperProps: {
                        variant: 'outlined',
                        elevation: 0,
                        sx: {
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: 1,
                            borderLeft: 'none',
                            borderRight: 'none',
                            borderRadius: 0
                        }
                    },
                    muiTableContainerProps: {
                        sx: {
                            maxHeight: 350,
                            minHeight: 'auto',
                            border: 'none'
                        }
                    },
                }}
            />
            {<SettingsSection>
                <Box>
                    <Typography variant="subtitle1" fontWeight="bold">Select Individual Student</Typography>
                    <Typography variant="subtitle2">Choose a specific student to directly associate with this
                        form request</Typography>
                </Box>
                <StudentsAutocomplete
                    label="Applications"
                    options={studentsData?.applications ?? []}
                    value={applicationFilters.filter(filter => !filter.isDeleted && studentsData?.applications.some(application => application.id === filter.id))}
                    onChange={(e, newValue) => onSettingsChange(prev => ({
                        ...prev,
                        applicationFilters: mergeArraysByKey(newValue, originalSettings.applicationFilters)
                    }))}
                />
                <StudentsAutocomplete
                    label="Students"
                    options={studentsData?.students ?? []}
                    value={studentFilters.filter(filter => !filter.isDeleted && studentsData?.students.some(student => student.id === filter.id))}
                    onChange={(e, newValue) => onSettingsChange(prev => ({
                        ...prev,
                        studentFilters: mergeArraysByKey(newValue, originalSettings.studentFilters)
                    }))}
                />
            </SettingsSection>
            }
        </Box>
    );
};

export default RegularFormSettings;