/* eslint-disable react/prop-types */

import React, {useState} from 'react';
import {Menu, MenuItem} from '@mui/material';
import IconButton from '@mui/material/IconButton';

const AdmicityIconMenu = ({state, icon, title, menuItems}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => setAnchorEl(null);

    return (
        <>
            <IconButton
                key={`toolbar-action-menu-button_${title}`}
                id="toolbar-action-menu-button"
                aria-label={title}
                onClick={handleClick}
            >{icon}
            </IconButton>
            <Menu
                key={`toolbar-action-menu_${title}`}
                id="toolbar-action-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'toolbar-action-menu-button',
                }}
            >
                {
                    menuItems.map(({label, onClick, ...rest}, index) => (
                        <MenuItem
                            key={index}
                            onClick={(e) => {
                                onClick(e, state);
                                handleClose();
                            }}
                            {...rest}
                        >{label}</MenuItem>
                    ))
                }
            </Menu>
        </>

    );
};

export default AdmicityIconMenu;