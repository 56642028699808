import React, {useEffect, useRef, useState} from 'react';
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    MenuItem,
    Radio,
    RadioGroup,
    TextField
} from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import RequiredCheckbox from './Shared/RequiredCheckbox';
import RichEditor from './Shared/RichEditor';
import RichEditorResultText from './Shared/RichEditorResultText';
import {stripHtmlTags} from './AdditionalNotes';

const LinearScale = ({
                         index,
                         id,
                         value,
                         onChange,
                         description,
                         isEditMode,
                         enableValidation,
                         startFrom,
                         endAt,
                         startLabel,
                         endLabel,
                         isFormArchived,
                         isSubmitted,
                         readonly,
                         placeholderValues,
                         accessLevel,
                         onUpdateDescription,
                         componentToEditIndex,
                     }) => {
    const startPositionOptions = [0, 1];
    const endPositionOptions = [2, 3, 4, 5, 6, 7, 8, 9, 10];
    const [currentValue, setCurrentValue] = useState(description);
    const prevPropRef = useRef();

    useEffect(() => {
        setCurrentValue(description)
    }, [description]);

    useEffect(() => {
        if (prevPropRef.current === index && componentToEditIndex !== prevPropRef.current) {
            onUpdateDescription({index, description: stripHtmlTags(currentValue), formattedDescription: currentValue});
        }
        prevPropRef.current = componentToEditIndex;
    }, [componentToEditIndex]);

    const generateRadioButtons = () => {
        let radios = [];
        for (let i = startFrom; i <= endAt; i++) {
            radios.push(
                <FormControlLabel
                    key={i}
                    value={i.toString()}
                    control={<Radio/>}
                    label={i.toString()}
                    labelPlacement="top"
                    sx={{width: '10px'}}
                />
            );
        }
        return radios;
    };

    return (
        <Box>
            {isEditMode
                ? <>
                    <RichEditor value={description} onChange={onChange} index={index} isEditMode={isEditMode}
                                isFormLive={isFormArchived} accessLevel={accessLevel} currentDescription={currentValue}
                                setCurrentDescription={setCurrentValue}/>
                    <Box display={'flex'} gap={2} alignItems={'center'}>
                        <TextField
                            select
                            value={startFrom}
                            variant={'standard'}
                            disabled={isFormArchived}
                            onChange={e => onChange({index, startFrom: e.target.value})}
                        >
                            {
                                startPositionOptions?.map(option =>
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                )
                            }
                        </TextField>
                        <Typography>to</Typography>
                        <TextField
                            select
                            variant={'standard'}
                            value={endAt}
                            disabled={isFormArchived}
                            onChange={e => onChange({index, endAt: e.target.value})}
                        >
                            {
                                endPositionOptions?.map(option =>
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                )
                            }
                        </TextField>
                    </Box>
                    <Box display={'flex'} gap={2} alignItems={'center'} py={2}>
                        <TextField
                            label={'Start label (optional)'}
                            value={startLabel}
                            disabled={isFormArchived}
                            onChange={e => onChange({index, startLabel: e.target.value})}
                        />
                        <TextField
                            label={'End label (optional)'}
                            value={endLabel}
                            disabled={isFormArchived}
                            onChange={e => onChange({index, endLabel: e.target.value})}
                        />
                    </Box>
                    <RequiredCheckbox enableValidation={enableValidation} index={index} onChange={onChange}
                                      isFormArchived={isFormArchived}/>
                </>
                : <>
                    <RichEditorResultText description={description} placeholderValues={placeholderValues}
                                          readonly={readonly} enableValidation={enableValidation}/>
                    <FormControl component="fieldset" sx={{width: '100%'}}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{width: '100%'}}>
                            <FormLabel component="legend">{startLabel}</FormLabel>
                            <RadioGroup
                                key={value}
                                row
                                aria-label="number"
                                sx={{flex: 1, justifyContent: 'space-around'}}
                                value={value}
                                onChange={e => onChange({id, value: e.target.value})}
                            >
                                {generateRadioButtons()}
                            </RadioGroup>
                            <FormLabel component="legend">{endLabel}</FormLabel>
                        </Box>
                        {value && !isSubmitted &&
                            <Button onClick={() => onChange({id, value: null})} color="secondary">Clear
                                Selection</Button>
                        }
                    </FormControl>
                </>
            }
        </Box>
    );
};

LinearScale.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    index: PropTypes.number,
    description: PropTypes.string,
    enableValidation: PropTypes.bool,
    onChange: PropTypes.func,
    isEditMode: PropTypes.bool,
    isFormArchived: PropTypes.bool,
    startFrom: PropTypes.number,
    endAt: PropTypes.number,
    startLabel: PropTypes.string,
    endLabel: PropTypes.string,
    isSubmitted: PropTypes.bool,
    readonly: PropTypes.bool,
    placeholderValues: PropTypes.object,
    accessLevel: PropTypes.number,
    onUpdateDescription: PropTypes.func,
    componentToEditIndex: PropTypes.number
};

export default LinearScale;