import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useGetGroupFiltersQuery} from '../../../api/services/groupService';
import {useNavigate} from 'react-router-dom';
import {selectProfile, setFilterId, setProfileFilters} from './ProfilesSlice';
import withPagination from '../../../hoc/pagination/withPagination';
import ProfilesGrid from './Components/ProfilesGrid';
import {useGetProfilesQuery} from '../../../api/services/profilesService';
import {Box, Chip, colors, InputAdornment, MenuItem, Stack, TextField} from '@mui/material';
import AdmicityDrawer from '../../../shared-components/AdmicityDrawer';
import {ROLES} from '../../../constants/roles';
import {resetPaginationState} from '../../../hoc/pagination/paginationSlice';
import Typography from '@mui/material/Typography';
import useUser from '../../../utility/hooks/useUser';
import SearchIcon from '@mui/icons-material/Search';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useDebounce from '../../../utility/hooks/useDebounce';
import APPLICANT_STATUSES from '../../../constants/applicantStatuses';
import AdmicityIconMenu from '../../../shared-components/AdmicityIconMenu';
import {downloadStudentsCsvReport, downloadStudentsXlsxReport} from '../../../api/services/filesService';
import useFileDownloader from '../../../utility/hooks/useFileDownloader';

const ProfilesOverview = () => {
    const {user} = useUser();
    const isParentRole = user.role === ROLES.PARENT;
    const filterId = useSelector(state => state.profilesInfo.filterId);
    const profileFilters = useSelector(state => state.profilesInfo.profileFilters);
    const {data: groupFilters} = !isParentRole ? useGetGroupFiltersQuery() : {data: {modules: []}};
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const debouncedSearchStudentName = useDebounce(profileFilters.keyword.value, 500);

    const fetchFunctionExtraProps = useMemo(() => ({
        filterId,
        role: user.role,
        status: profileFilters.status.value,
        searchTerms: {
            fullName: debouncedSearchStudentName
        },
    }), [filterId, user.role, profileFilters.status.value, debouncedSearchStudentName]);

    const fetchFunctionSettings = useMemo(() => ({
        skip: isParentRole ? !user.role : !filterId || !user.role
    }), [filterId, user.role, isParentRole]);

    const Table = useMemo(() => {
        return withPagination(
            ProfilesGrid,
            useGetProfilesQuery,
            fetchFunctionExtraProps,
            fetchFunctionSettings
        );
    }, [fetchFunctionExtraProps, fetchFunctionSettings]);

    const downloadReport = useFileDownloader();

    useEffect(() => {
        if (!filterId && groupFilters?.modules.length > 0) {
            dispatch(setFilterId(groupFilters.modules[0].id));
            dispatch(setProfileFilters({
                group: groupFilters.modules[0].name
            }));
        }
    }, [groupFilters]);

    const navigateToStudentProfile = (profile) => {
        dispatch(selectProfile(profile));
        navigate(`${profile.id}`);
    };

    const downloadCsvReport = async () =>
        await downloadReport(
            downloadStudentsCsvReport,
            {
                filterId,
                status: profileFilters.status.value,
                searchTerms: {
                    fullName: debouncedSearchStudentName
                }
            });

    const downloadXlsxReport = async () =>
        await downloadReport(
            downloadStudentsXlsxReport,
            {
                filterId,
                status: profileFilters.status.value,
                searchTerms: {
                    fullName: debouncedSearchStudentName
                }
            });

    return (
        <Box
            display="flex"
            flexGrow={1}
            minHeight="100%"
            marginTop={2}
            sx={{
                flexDirection: {
                    xs: 'column',
                    sm: 'column',
                    md: 'column',
                    lg: 'row',
                    xl: 'row',
                }
            }}
        >
            {
                groupFilters?.modules.length !== 0
                    ? <AdmicityDrawer
                        filters={groupFilters?.modules}
                        filterId={filterId}
                        onApply={(filterId, paths) => {
                            dispatch(setFilterId(filterId));
                            dispatch(resetPaginationState());
                            dispatch(setProfileFilters({
                                group: paths
                                    .filter((f, i) => i === 0 || f.selectable)
                                    .map(p => p.name)
                                    .join(' • '),
                                keyword: ''
                            }));
                        }}
                        onFilterDeleted={(id) => {
                            if (filterId === id) {
                                dispatch(setProfileFilters({
                                    group: groupFilters.modules[0].name
                                }));
                                dispatch(setFilterId(groupFilters.modules[0].id));
                            }
                        }}
                        defaultSelected={filterId}
                    />
                    : ''
            }
            <Box
                display="flex"
                flexDirection="column"
                width="100%"
                sx={{
                    flexGrow: 1,
                    minHeight: '100%',
                    paddingLeft: {
                        xs: 0,
                        sm: 0,
                        md: 0,
                        lg: 2,
                        xl: 2,
                    },
                    paddingRight: {
                        xs: 0,
                        sm: 0,
                        md: 0,
                        lg: 2,
                        xl: 2,
                    },
                }}
            >
                {
                    !isParentRole
                        ? <>
                            <Stack
                                direction="row"
                                alignItems="center"
                                gap={2}
                            >
                                <TextField
                                    select
                                    label={profileFilters.status.label}
                                    value={profileFilters.status.value}
                                    fullWidth
                                    sx={{maxWidth: '300px'}}
                                    onChange={e => {
                                        dispatch(setProfileFilters({
                                            status: e.target.value
                                        }));
                                        dispatch(resetPaginationState());
                                    }}>
                                    {
                                        APPLICANT_STATUSES.map(option =>
                                            <MenuItem key={option.label} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        )
                                    }
                                </TextField>
                                <TextField
                                    fullWidth
                                    placeholder="Enter name, surname, or full name"
                                    value={profileFilters.keyword.value}
                                    onChange={(e) => dispatch(setProfileFilters({
                                        keyword: e.target.value
                                    }))}
                                    inputProps={{maxLength: 200}}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon fontSize="small"/>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <AdmicityIconMenu
                                    icon={<MoreVertIcon/>}
                                    title="Download report"
                                    menuItems={
                                        [
                                            {
                                                label: 'Download CSV',
                                                onClick: downloadCsvReport
                                            },
                                            {
                                                label: 'Download XLSX',
                                                onClick: downloadXlsxReport
                                            }
                                        ]
                                    }
                                />
                            </Stack>
                            <Stack
                                flexDirection="row"
                                flexWrap="wrap"
                                gap={2}
                                marginTop={2}
                                marginBottom={2}
                            >
                                {
                                    Object.values(profileFilters)
                                        .filter(f => f.value && f.label !== profileFilters.status.label)
                                        .map(({label, value, deletable}) => (
                                            <Box
                                                key={label}
                                                display="inline-flex"
                                                flexDirection="row"
                                                alignItems="center"
                                                padding={1}
                                                border="1px dashed"
                                                borderRadius={2}
                                                borderColor={colors.blueGrey.A100}
                                                maxWidth={'100%'}
                                            >
                                                <Typography variant="subtitle2" fontWeight={600}>{label}:</Typography>
                                                <Chip
                                                    size="small"
                                                    sx={{
                                                        borderRadius: 2,
                                                        marginLeft: 1,
                                                        height: '100%',
                                                        '& .MuiChip-label': {
                                                            padding: 1,
                                                            overflowWrap: 'break-word',
                                                            whiteSpace: 'normal',
                                                            textOverflow: 'clip'
                                                        }
                                                    }}
                                                    label={value}
                                                    variant="outlined"
                                                    onDelete={deletable
                                                        ? () => dispatch(setProfileFilters({keyword: ''}))
                                                        : undefined
                                                    }
                                                />
                                            </Box>
                                        ))
                                }
                            </Stack>
                        </>
                        : ''
                }
                <Table
                    onProfileSelect={navigateToStudentProfile}
                    noRowsOverlay={{
                        component: groupFilters?.modules.length > 0
                            ? <>
                                <Typography variant="h6">No Student Profiles Found</Typography>
                                {
                                    !isParentRole
                                        ? <Typography variant="subtitle1">No student profiles match the selected filter
                                            criteria.</Typography>
                                        : ''
                                }
                            </>
                            : <>
                                <Typography variant="h6">No Data</Typography>
                                <Typography variant="subtitle1">Data is not synchronised yet.</Typography>
                            </>
                    }}
                />
            </Box>
        </Box>
    );
};

export default ProfilesOverview;