import React, {useEffect, useRef, useState} from 'react';
import {Box, MenuItem, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import EditMultiFieldsBuilder from './Shared/EditMultiFieldsBuilder';
import RichEditorResultText from './Shared/RichEditorResultText';
import {stripHtmlTags} from './AdditionalNotes';

const Dropdown = ({
                      index,
                      id,
                      value,
                      onChange,
                      description,
                      isEditMode,
                      enableValidation,
                      options,
                      isFormArchived,
                      relatedComponent,
                      readonly,
                      placeholderValues,
                      accessLevel,
                      onUpdateDescription,
                      componentToEditIndex,
                  }) => {
    const [currentValue, setCurrentValue] = useState(description);
    const prevPropRef = useRef();

    useEffect(() => {
        setCurrentValue(description)
    }, [description]);

    useEffect(() => {
        if (prevPropRef.current === index && componentToEditIndex !== prevPropRef.current) {
            onUpdateDescription({index, description: stripHtmlTags(currentValue), formattedDescription: currentValue});
        }
        prevPropRef.current = componentToEditIndex;
    }, [componentToEditIndex]);

    return <Box>
        {isEditMode
            ? <EditMultiFieldsBuilder onChange={onChange} enableValidation={enableValidation}
                                      description={description} index={index} options={options}
                                      isFormArchived={isFormArchived} optionLengthLimit={180}
                                      relatedComponent={relatedComponent} accessLevel={accessLevel}
                                      currentDescription={currentValue} setCurrentDescription={setCurrentValue}/>
            : <>
                <RichEditorResultText description={description} placeholderValues={placeholderValues}
                                      readonly={readonly} enableValidation={enableValidation}/>
                <Box key={`${description}-${value}`} display={'flex'} justifyContent={'center'}>
                    <TextField
                        select
                        value={value}
                        fullWidth
                        sx={{maxWidth: '650px'}}
                        onChange={e => onChange({id, radioValue: e.target.value})}
                    >
                        {
                            options?.map(option =>
                                <MenuItem key={option.id} value={option.id}
                                          sx={{maxWidth: '650px', whiteSpace: 'normal'}}>
                                    {option.description}
                                </MenuItem>
                            )
                        }
                    </TextField>
                </Box>
            </>
        }
    </Box>
}

Dropdown.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    index: PropTypes.number,
    options: PropTypes.array,
    description: PropTypes.string,
    enableValidation: PropTypes.bool,
    onChange: PropTypes.func,
    isEditMode: PropTypes.bool,
    isFormArchived: PropTypes.bool,
    relatedComponent: PropTypes.object,
    readonly: PropTypes.bool,
    placeholderValues: PropTypes.object,
    accessLevel: PropTypes.number,
    onUpdateDescription: PropTypes.func,
    componentToEditIndex: PropTypes.number
};

export default Dropdown;