import React, {useEffect, useRef, useState} from 'react';
import {Box, Checkbox, Stack} from '@mui/material';
import PropTypes from 'prop-types';
import RequiredCheckbox from './Shared/RequiredCheckbox';
import RichEditor from './Shared/RichEditor';
import RichEditorResultText from './Shared/RichEditorResultText';
import {stripHtmlTags} from './AdditionalNotes';

const CheckboxQuestion = ({
                              index,
                              id,
                              value,
                              onChange,
                              description,
                              isEditMode,
                              enableValidation,
                              isFormArchived,
                              readonly,
                              placeholderValues,
                              accessLevel,
                              onUpdateDescription,
                              componentToEditIndex,
                          }) => {
    const [currentValue, setCurrentValue] = useState(description);
    const prevPropRef = useRef();

    useEffect(() => {
        setCurrentValue(description)
    }, [description]);

    useEffect(() => {
        if (prevPropRef.current === index && componentToEditIndex !== prevPropRef.current) {
            onUpdateDescription({index, description: stripHtmlTags(currentValue), formattedDescription: currentValue});
        }
        prevPropRef.current = componentToEditIndex;
    }, [componentToEditIndex]);

    return (
        <Box>
            {isEditMode
                ? <>
                    <RichEditor value={description} onChange={onChange} index={index} isEditMode={isEditMode}
                                isFormLive={isFormArchived} accessLevel={accessLevel} currentDescription={currentValue}
                                setCurrentDescription={setCurrentValue}/>
                    <Stack flexDirection="row" justifyContent="space-between" gap={3}>
                        <RequiredCheckbox enableValidation={enableValidation} index={index} onChange={onChange}
                                          isFormArchived={isFormArchived}/>
                    </Stack>
                </>
                : <Box key={`${description}-${value}`} display={'flex'} alignItems={'center'}>
                    <RichEditorResultText description={description} placeholderValues={placeholderValues}
                                          readonly={readonly} enableValidation={enableValidation}/>
                    <Checkbox value={value} checked={value}
                              onChange={e => onChange({id, value: e.target.checked})}/>
                </Box>
            }
        </Box>
    );
};

CheckboxQuestion.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    index: PropTypes.number,
    description: PropTypes.string,
    enableValidation: PropTypes.bool,
    onChange: PropTypes.func,
    isEditMode: PropTypes.bool,
    isFormArchived: PropTypes.bool,
    readonly: PropTypes.bool,
    placeholderValues: PropTypes.object,
    accessLevel: PropTypes.number,
    onUpdateDescription: PropTypes.func,
    componentToEditIndex: PropTypes.number
};

export default CheckboxQuestion;