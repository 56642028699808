import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, FormControlLabel, MenuItem, Radio, RadioGroup, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import RichEditorResultText from './Shared/RichEditorResultText';
import RichEditor from './Shared/RichEditor';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';
import RequiredCheckbox from './Shared/RequiredCheckbox';
import {useGetApplicationStatusesQuery} from '../../../../../api/services/schoolManagementService';
import {stripHtmlTags} from './AdditionalNotes';

const OfferStatusChoice = ({
                               index,
                               id,
                               value,
                               onChange,
                               description,
                               isEditMode,
                               enableValidation,
                               options,
                               isFormArchived,
                               relatedComponent,
                               readonly,
                               placeholderValues,
                               accessLevel,
                               onUpdateDescription,
                               componentToEditIndex,
                           }) => {
    const {data: applicationStatuses = []} = useGetApplicationStatusesQuery({}, {skip: !isEditMode});
    const [currentValue, setCurrentValue] = useState(description);
    const prevPropRef = useRef();

    useEffect(() => {
        setCurrentValue(description)
    }, [description]);

    useEffect(() => {
        if (prevPropRef.current === index && componentToEditIndex !== prevPropRef.current) {
            onUpdateDescription({index, description: stripHtmlTags(currentValue), formattedDescription: currentValue});
        }
        prevPropRef.current = componentToEditIndex;
    }, [componentToEditIndex]);

    return (
        <Box>
            {isEditMode
                ? <React.Fragment>
                    <Box>
                        <RichEditor value={description} onChange={onChange} index={index}
                                    isFormLive={isFormArchived} accessLevel={accessLevel} currentDescription={currentValue} setCurrentDescription={setCurrentValue}/>
                        <Box>
                            {options.map((option, optionIndex) =>
                                <Box
                                    py={1}
                                    display={'flex'}
                                    justifyContent={'space-between'}
                                    gap={1}
                                    alignItems={'center'}
                                    key={`offerStatusChoiceOption-${optionIndex}`}
                                >
                                    <Typography>{optionIndex + 1}.</Typography>
                                    <TextField
                                        multiline
                                        fullWidth
                                        sx={{
                                            flex: 2
                                        }}
                                        value={option.description}
                                        disabled={isFormArchived}
                                        label={!option.description ? 'Enter a choice for your respondents to select' : null}
                                        InputLabelProps={{
                                            shrink: false,
                                        }}
                                        onChange={e =>
                                            onChange({
                                                index,
                                                option: {
                                                    ...option,
                                                    description: e.target.value
                                                },
                                                optionId: option.id
                                            })}
                                    />
                                    <TextField
                                        select
                                        required
                                        sx={{
                                            flex: 1
                                        }}
                                        error={!option?.value || (option?.value && !applicationStatuses.some(x => `${x.id}` === option.value))}
                                        value={option.value}
                                        label="Application Status"
                                        onChange={e =>
                                            onChange({
                                                index,
                                                option: {
                                                    ...option,
                                                    value: e.target.value
                                                },
                                                optionId: option.id,
                                                isValid: !option?.value || (option?.value && !applicationStatuses.some(x => `${x.id}` === option.value))
                                            })}
                                    >
                                        {
                                            applicationStatuses.map(({id, description}) => (
                                                <MenuItem
                                                    key={id}
                                                    value={`${id}`}
                                                >{description}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                    <Tooltip
                                        title={relatedComponent?.targetState === option.id
                                            ? `This option cannot be deleted as it is currently used in the ${relatedComponent?.relatedFormName} form.`
                                            : null}
                                        disabled={isFormArchived}
                                    >
                                        <IconButton>
                                            <DeleteIcon
                                                onClick={() => relatedComponent?.targetState === option.id || isFormArchived
                                                    ? {}
                                                    : onChange({index, isRemove: true, optionId: option.id})}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            )
                            }
                        </Box>
                        <Button onClick={() => onChange({index, isAdd: true})} startIcon={<AddIcon/>}
                                cursor={'pointer'}
                                disabled={isFormArchived}>
                            Add option
                        </Button>
                    </Box>
                    <RequiredCheckbox enableValidation={enableValidation} index={index} onChange={onChange}
                                      isFormArchived={isFormArchived}/>
                </React.Fragment>
                : <Box key={`offerStatusChoice-${index}`}>
                    <RichEditorResultText
                        description={description}
                        placeholderValues={placeholderValues}
                        readonly={readonly}
                        enableValidation={enableValidation}/>
                    <RadioGroup
                        value={value}
                        onChange={e => onChange({
                            id,
                            radioValue: e.target.value
                        })}
                    >
                        {options.map((option, index) =>
                            <FormControlLabel
                                key={`offerStatusChoiceOption-${index}`}
                                sx={{
                                    '& .MuiTypography-root': {
                                        wordBreak: 'break-word',
                                        maxWidth: '800px',
                                    }
                                }}
                                value={option.id}
                                control={<Radio/>}
                                label={option.description}
                            />
                        )}
                    </RadioGroup>
                </Box>
            }
        </Box>
    );
};

OfferStatusChoice.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    index: PropTypes.number,
    options: PropTypes.array,
    description: PropTypes.string,
    enableValidation: PropTypes.bool,
    onChange: PropTypes.func,
    isEditMode: PropTypes.bool,
    isFormArchived: PropTypes.bool,
    relatedComponent: PropTypes.object,
    readonly: PropTypes.bool,
    placeholderValues: PropTypes.object,
    accessLevel: PropTypes.number,
    onUpdateDescription: PropTypes.func,
    componentToEditIndex: PropTypes.number
};

export default OfferStatusChoice;